import Vue from 'vue';
import _ from 'lodash';

function convertPayloadToFloat(payload) {
  if (payload.bonus_amount) {
    payload.bonus_amount = payload.bonus_amount.toFixed(2);
  }
}

const defaultPage = 1;
const paginationLimit = 20;

export default {
  namespaced: true,
  state: {
    vouchers: [],
    voucherOperations: [],
    voucher: { system_status: {}, dormancy: { settings: {} }, activation: {} },
    voucherStores: [],
    list: {
      keyword: '',
      partner_id: '',
      offer_id: '',
      origin_order_id: '',
      activation_code_batch_id: '',
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    vouchersFromSearch: (state) => state.vouchers,
    voucher: (state) => state.voucher,
    vouchers: (state) => state.vouchers,
    voucherOperations: (state) => state.voucherOperations,
    latestBlockVoucherOperation: (state) => _.reverse(_.sortBy(_.filter(state.voucherOperations, ['type', 'voucher_block']), ['creation_date']))[0],
    voucherStores: (state) => state.voucherStores,
    listKeyword: (state) => state.list.keyword,
    listPartnerId: (state) => state.list.partner_id,
    listOfferId: (state) => state.list.offer_id,
    listOriginOrderId: (state) => state.list.origin_order_id,
    listActivationCodeBatchId: (state) => state.list.activation_code_batch_id,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
  },
  mutations: {
    vouchers(state, vouchers) {
      state.vouchers = vouchers;
    },
    voucherOperations(state, voucherOperations) {
      state.voucherOperations = voucherOperations;
    },
    voucher(state, voucher) {
      if (!voucher) {
        voucher = { system_status: {}, dormancy: { settings: {} }, activation: {} };
      }
      state.voucher = voucher;
    },
    voucherStores(state, stores) {
      state.voucherStores = stores;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listPartnerId(state, partner_id) {
      state.list.partner_id = partner_id;
    },
    listOfferId(state, offer_id) {
      state.list.offer_id = offer_id;
    },
    listOriginOrderId(state, origin_order_id) {
      state.list.origin_order_id = origin_order_id;
    },
    listActivationCodeBatchId(state, activation_code_batch_id) {
      state.list.activation_code_batch_id = activation_code_batch_id;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPage(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
    listPageNumber(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
  },
  actions: {
    async downloadConsumerGiftCardOrderInvoice(context, { language, id }) {
      const accountService = Vue.prototype.$services.account;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { fileContent, filename } = await accountService.getConsumerGiftCardOrderInvoice(id, language);
      await fileSaver.saveFile(fileContent, filename);

      return { success: true };
    },
    async downloadOrderInvoice(context, { language, id }) {
      const accountService = Vue.prototype.$services.account;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { fileContent, filename } = await accountService.getOrderInvoice(id, language);
      await fileSaver.saveFile(fileContent, filename);

      return { success: true };
    },
    createBonusVoucher({ commit }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const payload = _.cloneDeep(params);

      convertPayloadToFloat(payload);

      return voucherService.createBonusVoucher(payload);
    },
    async listVouchers({ commit }, { keyword, partner_id, offer_id, origin_order_id, activation_code_batch_id, activation_code, page, limit } = {}) {
      const voucherService = Vue.prototype.$services.voucher;

      const thePage = page || state.list.pageNumber || 1;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';
      const thePartnerId = partner_id || '';
      const theOfferId = offer_id || '';
      const theOrderId = origin_order_id || '';
      const theBatchId = activation_code_batch_id || '';

      const listResults = await voucherService.listVouchers({
        keyword: theKeyword,
        partner_id: thePartnerId,
        offer_id: theOfferId,
        origin_order_id: theOrderId,
        activation_code_batch_id: theBatchId,
        activation_code,
        page: thePage,
        limit: theLimit,
      });

      const vouchers = listResults.items;

      commit('vouchers', vouchers);
      commit('listPageNumber', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('listKeyword', theKeyword);
      commit('listPartnerId', thePartnerId);
      commit('listOfferId', theOfferId);
      commit('listOriginOrderId', theOrderId);
      commit('listActivationCodeBatchId', theBatchId);
    },
    async clearListVouchers({ commit }) {
      commit('vouchers', []);
    },
    async listVouchersByOffer({ commit }, { offerPromotionId, offerId, keyword, page, limit }) {
      const voucherService = Vue.prototype.$services.voucher;
      const thePage = page || state.list.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';

      const listResults = await voucherService.listVouchersByOffer(offerId, thePage, theLimit, theKeyword, offerPromotionId);
      commit('listKeyword', theKeyword);
      commit('listPage', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('vouchers', listResults.items);
    },
    async listVoucherOperations({ commit }, id) {
      const voucherService = Vue.prototype.$services.voucher;
      const voucherOperations = await voucherService.listVoucherOperations(id, 1, 1000);

      commit('voucherOperations', voucherOperations);
    },
    async extendVoucherExpiration({ dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;
      const result = await voucherService.extendVoucherExpiration(id, payload);
      await dispatch('getVoucher', id);
      return result;
    },
    async applyVoucherDormancyExtension({ dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;
      const result = await voucherService.applyVoucherDormancyExtension(id, payload);
      await dispatch('getVoucher', id);
      return result;
    },
    async transferVoucher({ dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;

      const result = await voucherService.transferVoucher(id, payload);
      await dispatch('getVoucher', id);
      return result;
    },
    async refundVoucherOrder({ dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;
      const result = await voucherService.refundVoucherOrder(id, payload);
      await dispatch('getVoucher', id);
      return result;
    },
    async refundVoucher({ dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;
      const result = await voucherService.refundVoucher(id, payload);
      await dispatch('getVoucher', id);
      return result;
    },
    async cancelVoucher({ dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;
      const result = await voucherService.cancelVoucher(id, payload);
      await dispatch('getVoucher', id);
      return result;
    },
    async writeOffVoucher({ dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;
      const result = await voucherService.writeOffVoucher(id, payload);
      await dispatch('getVoucher', id);
      return result;
    },
    async removeWriteOffVoucher({ dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;

      const result = await voucherService.removeWriteOffVoucher(params.id);
      await dispatch('getVoucher', params.id);
      return result;
    },
    async blockVoucher({ dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;
      const result = await voucherService.blockVoucher(id, payload);
      await dispatch('getVoucher', id);
      return result;
    },
    async unblockVoucher({ dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;
      const result = await voucherService.unblockVoucher(id, payload);
      await dispatch('getVoucher', id);
      return result;
    },
    async forceClose({ dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;
      const result = await voucherService.forceClose(id, payload);
      await dispatch('getVoucher', id);
      return result;
    },
    async getVoucher({ commit, dispatch }, id) {
      const voucherService = Vue.prototype.$services.voucher;
      const voucher = await voucherService.getVoucher(id);
      commit('voucher', voucher);

      await dispatch('listVoucherOperations', id);

      return voucher;
    },
    async searchVouchers({ commit }, keyword) {
      const voucherService = Vue.prototype.$services.voucher;
      const result = await voucherService.searchVouchers({ keyword });
      const vouchers = result.items;

      commit('vouchers', vouchers);
    },
    async clearSearchVouchers({ commit }) {
      commit('vouchers', []);
    },
    async unloadVoucher({ commit, dispatch }, id) {
      const voucherService = Vue.prototype.$services.voucher;
      const voucher = await voucherService.unloadVoucher(id);
      commit('voucher', voucher);

      await dispatch('getVoucher', id);

      return voucher;
    },

    async loadVoucher({ commit, dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;
      const voucher = await voucherService.loadVoucher(id, payload);
      commit('voucher', voucher);

      await dispatch('getVoucher', id);

      return voucher;
    },
    async adjustVoucherBalance({ commit, dispatch }, params) {
      const voucherService = Vue.prototype.$services.voucher;
      const { id, ...payload } = params;
      const voucher = await voucherService.adjustVoucherBalance(id, payload);

      await dispatch('getVoucher', id);

      return voucher;
    },
  },
};
